import type { MaybeRef } from "@vueuse/core";
import { useI18n as useI18nHook } from "vue-i18n";

const I18N_LOCALE_STATE_VARIABLE_KEY = "i18n_locale";

export const useI18n = useI18nHook;

export function useI18nLocale() {
  return useState(I18N_LOCALE_STATE_VARIABLE_KEY, () => "fr");
}

export const useI18nList = (list: MaybeRef<string[]>) => {
  const locale = useI18nLocale();

  return computed(() => {
    const unwrappedRef = unref(list);

    if (Intl.ListFormat)
      return new Intl.ListFormat(locale.value).format(unwrappedRef);

    return unwrappedRef.join(", ");
  });
};

export const useI18nDate = (
  date: MaybeRef<string>,
  options?: Intl.DateTimeFormatOptions & { locale?: string },
) => {
  const locale = useI18nLocale();

  return computed(() => {
    const unwrapped = unref(date);

    return Intl.DateTimeFormat(options?.locale ?? locale.value, {
      timeZone: "Europe/Paris",
      ...options,
    }).format(new Date(unwrapped));
  });
};

export const useI18nRelativeTime = (
  date: MaybeRef<string>,
  options?: Intl.RelativeTimeFormatOptions,
) => {
  const locale = useI18nLocale();
  const nowTimestamp = Date.now();

  return computed(() => {
    const dateTimestamp = new Date(unref(date)).getTime();
    const diffInMinutes = Math.ceil((dateTimestamp - nowTimestamp) / 1000 / 60);
    const absoluteDiffInMinutes = Math.abs(diffInMinutes);

    const intl = new Intl.RelativeTimeFormat(locale.value, {
      ...options,
      numeric: "auto",
    });

    if (absoluteDiffInMinutes < 1) {
      return intl.format(diffInMinutes / 60, "second");
    }

    if (absoluteDiffInMinutes < 60) {
      return intl.format(diffInMinutes, "minute");
    }

    if (absoluteDiffInMinutes < 60 * 23) {
      return intl.format(Math.round(diffInMinutes / 60), "hour");
    }

    if (absoluteDiffInMinutes < 60 * 24 * 6) {
      return intl.format(Math.round(diffInMinutes / 60 / 24), "day");
    }

    if (absoluteDiffInMinutes < 60 * 24 * 30) {
      return intl.format(Math.round(diffInMinutes / 60 / 24 / 7), "week");
    }

    if (absoluteDiffInMinutes < 60 * 24 * 365) {
      return intl.format(Math.round(diffInMinutes / 60 / 24 / 30), "month");
    }

    return useI18nDate(date, { dateStyle: "short" }).value;
  });
};

export const useI18nMoney = (
  amount: MaybeRef<DatabaseCompositeType<"money_amount">>,
  options?: Omit<Intl.NumberFormatOptions, "style"> & {
    excludingTaxesSuffix?: boolean;
    includingTaxesSuffix?: boolean;
    html?: boolean;
    i18n?: ReturnType<typeof useI18n>;
  },
) => {
  const { locale, t } = options?.i18n ?? useI18n();

  return computed(() => {
    const unwrapped = unref(amount);
    let suffix = "";

    if (options?.excludingTaxesSuffix) {
      suffix = t("app.money.excluding_taxes_suffix");
    }

    if (options?.includingTaxesSuffix) {
      suffix = t("app.money.including_taxes_suffix");
    }

    if (suffix && options?.html) {
      suffix = `<span class="text-[.6em] font-normal">${suffix}</span>`;
    }

    return (
      Intl.NumberFormat(locale.value, {
        currency: unwrapped.currency ?? undefined,
        style: "currency",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        ...options,
      }).format(unwrapped.amount ?? 0) + (suffix ? ` ${suffix}` : "")
    );
  });
};

export const useI18nNumber = (
  value: MaybeRef<number>,
  options?: Intl.NumberFormatOptions,
) => {
  const locale = useI18nLocale();

  return computed(() =>
    new Intl.NumberFormat(locale.value, options).format(unref(value)),
  );
};

export const modalRegistry = {
  confirmation: defineAsyncComponent(
    () => import("./components/modal-confirmation.vue"),
  ),
  tagEdition: defineAsyncComponent(
    () => import("@/business-areas/tag/components/tag-edition-modal.vue"),
  ),
  courseSessionEdition: defineAsyncComponent(
    () =>
      import(
        "@/business-areas/course-session/components/course-session-edition-modal.vue"
      ),
  ),
  courseSessionRegistration: defineAsyncComponent(
    () =>
      import(
        "@/business-areas/course-session-registration/components/course-session-registration-modal.vue"
      ),
  ),
  placeEditionModal: defineAsyncComponent(
    () => import("@/business-areas/places/components/place-edition-modal.vue"),
  ),
  organizationCreationModal: defineAsyncComponent(
    () =>
      import(
        "@/business-areas/organizations/components/organization-creation-modal.vue"
      ),
  ),
  courseSessionAttendeeEditionModal: defineAsyncComponent(
    () =>
      import(
        "@/business-areas/course-session-attendee/components/course-session-attendee-edition-modal.vue"
      ),
  ),
  ratingReconciliationModal: defineAsyncComponent(
    () =>
      import(
        "@/business-areas/course-session-attendee/components/course-session-attendee-rating-reconciliation-modal.vue"
      ),
  ),
  creditPackEditionModal: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/credit-pack/components/credit-pack-edition-modal.vue"
      ),
  ),
  batchRegistrationModal: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/course-session-registration/components/course-session-batch-registration-modal.vue"
      ),
  ),
  organizationSessionTransactionEditionModal: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/organization-sessions/components/organization-session-transaction-edition-modal.vue"
      ),
  ),
  organizationOwnerInvitationDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/organization-sessions/components/organization-owner-invitation-modal.vue"
      ),
  ),
  organizationTrackEditionModal: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/organization-track/components/organization-track-edition-modal.vue"
      ),
  ),
  postSessionAttendeeEmailResend: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/email/components/post-session-attendee-email-resend-modal.vue"
      ),
  ),
  resourceEditionDialog: defineAsyncComponent(
    () =>
      import("~/business-areas/resource/components/resource-edition-modal.vue"),
  ),
  tagPageEditionModal: defineAsyncComponent(
    () =>
      import("~/business-areas/tag-page/components/tag-page-edition-modal.vue"),
  ),
  companyEditionModal: defineAsyncComponent(
    () =>
      import("~/business-areas/company/components/company-edition-modal.vue"),
  ),
  proposalCreationModal: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/proposal/components/proposal-creation-modal.vue"
      ),
  ),
  proposalSearchableLineItemModal: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/proposal/components/proposal-searchable-line-item-modal.vue"
      ),
  ),
  proposalLineItemTeacherDescriptionListModal: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/proposal/components/proposal-line-item-teacher-description-list-modal.vue"
      ),
  ),
  projectCreationModal: defineAsyncComponent(
    () =>
      import("~/business-areas/project/components/project-creation-modal.vue"),
  ),
  userEditionModal: defineAsyncComponent(
    () => import("~/business-areas/user/components/user-edition-modal.vue"),
  ),
};

export type AppModalId = keyof typeof modalRegistry;

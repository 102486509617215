export default defineNuxtRouteMiddleware(async (to) => {
  if (to.name === "auth-callback") return;

  const { isAdmin, isAuthenticated } = useCurrentUser();

  if (!isAdmin.value) {
    await useDatabaseClient().auth.signOut({ scope: "local" });
  }

  if (!isAuthenticated.value && !to.meta.public)
    return navigateTo("/auth/login");
});

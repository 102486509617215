import "~/shared/theme/theme.css";
import "~/shared/theme/vuetify/_vuetify-styles.scss";

import { createVuetify } from "vuetify";

import { themeIconsConfiguration } from "~/shared/theme/icons/theme-icons.config";
import { themes } from "~/shared/theme/theme-color-schemas";

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    defaults: {
      VCard: {
        elevation: 4,
      },
      VToolbar: {
        VBtn: { variant: undefined },
      },
      VSwitch: {
        density: "compact",
        color: "primary",
      },
      VTooltip: {
        transition: "fade-transition",
      },
      VCombobox: {
        color: "primary",
        density: "compact",
        variant: "outlined",
      },
      VTextarea: {
        variant: "outlined",
        density: "compact",
        color: "primary",
        autoGrow: true,
      },
      VDataTable: {
        density: "compact",
      },
      VDataTableServer: {
        density: "compact",
      },
    },

    theme: {
      themes,
      defaultTheme: "light",
      variations: {
        colors: ["primary", "accent"],
        lighten: 5,
        darken: 5,
      },
    },
    icons: themeIconsConfiguration,
  });

  nuxtApp.vueApp.use(vuetify);
});


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as callbackQbPxmRUcnWMeta } from "/opt/render/project/src/apps/admin/client/pages/auth/callback.vue?macro=true";
import { default as loginjitDydJNFXMeta } from "/opt/render/project/src/apps/admin/client/pages/auth/login.vue?macro=true";
import { default as companiesjRcpYJSmOQMeta } from "/opt/render/project/src/apps/admin/client/pages/companies.vue?macro=true";
import { default as indexsLlWPMWwZAMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/index.vue?macro=true";
import { default as mediasWvwnkdj1dtMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/medias.vue?macro=true";
import { default as program8eaYcWSaRpMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/program.vue?macro=true";
import { default as resourcesf8rfA31rBkMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/resources.vue?macro=true";
import { default as sessionsPEnFb6Fd9eMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/sessions.vue?macro=true";
import { default as _91courseId_93XYodBh1J5SMeta } from "/opt/render/project/src/apps/admin/client/pages/courses/[courseId].vue?macro=true";
import { default as indexXCPEDdp5O5Meta } from "/opt/render/project/src/apps/admin/client/pages/courses/index.vue?macro=true";
import { default as newAOqAArjV52Meta } from "/opt/render/project/src/apps/admin/client/pages/courses/new.vue?macro=true";
import { default as _91templateId_93UoR7beZmHQMeta } from "/opt/render/project/src/apps/admin/client/pages/emails/[templateId].vue?macro=true";
import { default as indexL30kdJQ3nPMeta } from "/opt/render/project/src/apps/admin/client/pages/emails/index.vue?macro=true";
import { default as emailscD7JPPaftvMeta } from "/opt/render/project/src/apps/admin/client/pages/emails.vue?macro=true";
import { default as creditsZKpzQfWbz0Meta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/credits.vue?macro=true";
import { default as indexITiogwHrJ2Meta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/index.vue?macro=true";
import { default as invitationsdOy6tE4e7eMeta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/invitations.vue?macro=true";
import { default as sessionsXWamzCOMwkMeta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/sessions.vue?macro=true";
import { default as tracksZMMoLXSZj8Meta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/tracks.vue?macro=true";
import { default as _91organizationId_931vkCExK0zQMeta } from "/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId].vue?macro=true";
import { default as indexqI8pL6I3wfMeta } from "/opt/render/project/src/apps/admin/client/pages/organizations/index.vue?macro=true";
import { default as indexVTz3bxmeEzMeta } from "/opt/render/project/src/apps/admin/client/pages/places/index.vue?macro=true";
import { default as projectsbcnDOprBw0Meta } from "/opt/render/project/src/apps/admin/client/pages/projects.vue?macro=true";
import { default as indexzv03agIEdfMeta } from "/opt/render/project/src/apps/admin/client/pages/proposals/[id]/index.vue?macro=true";
import { default as indexnTPCuIUXNLMeta } from "/opt/render/project/src/apps/admin/client/pages/proposals/index.vue?macro=true";
import { default as indexKVv47dBYVtMeta } from "/opt/render/project/src/apps/admin/client/pages/sessions/[sessionId]/index.vue?macro=true";
import { default as indexhtseBig3z6Meta } from "/opt/render/project/src/apps/admin/client/pages/sessions/index.vue?macro=true";
import { default as settings50EfYOw30ZMeta } from "/opt/render/project/src/apps/admin/client/pages/settings.vue?macro=true";
import { default as indexWnRTupFlHbMeta } from "/opt/render/project/src/apps/admin/client/pages/tag-pages/index.vue?macro=true";
import { default as _91_46_46_46path_93UkKtSQSLWzMeta } from "/opt/render/project/src/apps/admin/client/pages/tags/[...path].vue?macro=true";
import { default as index_45oldZIEzwbYLJdMeta } from "/opt/render/project/src/apps/admin/client/pages/tags/index-old.vue?macro=true";
import { default as coursesEzKzMeGzOwMeta } from "/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId]/courses.vue?macro=true";
import { default as indexVp37NnkKCBMeta } from "/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId]/index.vue?macro=true";
import { default as _91teacherId_93DxUMzf2AoXMeta } from "/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId].vue?macro=true";
import { default as indexSQBRMuTOY4Meta } from "/opt/render/project/src/apps/admin/client/pages/teachers/index.vue?macro=true";
import { default as newI9zRTZSlU2Meta } from "/opt/render/project/src/apps/admin/client/pages/teachers/new.vue?macro=true";
import { default as userstIAYznNLVQMeta } from "/opt/render/project/src/apps/admin/client/pages/users.vue?macro=true";
import { default as _91playback_id_93SJtCUEnHbKMeta } from "/opt/render/project/src/apps/admin/client/pages/videos/[playback_id].vue?macro=true";
export default [
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callbackQbPxmRUcnWMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/auth/callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginjitDydJNFXMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/auth/login.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/companies.vue")
  },
  {
    name: _91courseId_93XYodBh1J5SMeta?.name,
    path: "/courses/:courseId()",
    meta: _91courseId_93XYodBh1J5SMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId].vue"),
    children: [
  {
    name: "courses-courseId",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/index.vue")
  },
  {
    name: "courses-courseId-medias",
    path: "medias",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/medias.vue")
  },
  {
    name: "courses-courseId-program",
    path: "program",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/program.vue")
  },
  {
    name: "courses-courseId-resources",
    path: "resources",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/resources.vue")
  },
  {
    name: "courses-courseId-sessions",
    path: "sessions",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/[courseId]/sessions.vue")
  }
]
  },
  {
    name: "courses",
    path: "/courses",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/index.vue")
  },
  {
    name: "courses-new",
    path: "/courses/new",
    meta: newAOqAArjV52Meta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/courses/new.vue")
  },
  {
    name: emailscD7JPPaftvMeta?.name,
    path: "/emails",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/emails.vue"),
    children: [
  {
    name: "emails-templateId",
    path: ":templateId()",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/emails/[templateId].vue")
  },
  {
    name: "emails",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/emails/index.vue")
  }
]
  },
  {
    name: _91organizationId_931vkCExK0zQMeta?.name,
    path: "/organizations/:organizationId()",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId].vue"),
    children: [
  {
    name: "organizations-organizationId-credits",
    path: "credits",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/credits.vue")
  },
  {
    name: "organizations-organizationId",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/index.vue")
  },
  {
    name: "organizations-organizationId-invitations",
    path: "invitations",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/invitations.vue")
  },
  {
    name: "organizations-organizationId-sessions",
    path: "sessions",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/sessions.vue")
  },
  {
    name: "organizations-organizationId-tracks",
    path: "tracks",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/[organizationId]/tracks.vue")
  }
]
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/organizations/index.vue")
  },
  {
    name: "places",
    path: "/places",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/places/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/projects.vue")
  },
  {
    name: "proposals-id",
    path: "/proposals/:id()",
    meta: indexzv03agIEdfMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/proposals/[id]/index.vue")
  },
  {
    name: "proposals",
    path: "/proposals",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/proposals/index.vue")
  },
  {
    name: "sessions-sessionId",
    path: "/sessions/:sessionId()",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/sessions/[sessionId]/index.vue")
  },
  {
    name: "sessions",
    path: "/sessions",
    meta: indexhtseBig3z6Meta || {},
    alias: ["/"],
    component: () => import("/opt/render/project/src/apps/admin/client/pages/sessions/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/settings.vue")
  },
  {
    name: "tag-pages",
    path: "/tag-pages",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/tag-pages/index.vue")
  },
  {
    name: "tags-path",
    path: "/tags/:path(.*)*",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/tags/[...path].vue")
  },
  {
    name: "tags-index-old",
    path: "/tags/index-old",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/tags/index-old.vue")
  },
  {
    name: _91teacherId_93DxUMzf2AoXMeta?.name,
    path: "/teachers/:teacherId()",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId].vue"),
    children: [
  {
    name: "teachers-teacherId-courses",
    path: "courses",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId]/courses.vue")
  },
  {
    name: "teachers-teacherId",
    path: "",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/teachers/[teacherId]/index.vue")
  }
]
  },
  {
    name: "teachers",
    path: "/teachers",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/teachers/index.vue")
  },
  {
    name: "teachers-new",
    path: "/teachers/new",
    meta: newI9zRTZSlU2Meta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/teachers/new.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/opt/render/project/src/apps/admin/client/pages/users.vue")
  },
  {
    name: "videos-playback_id",
    path: "/videos/:playback_id()",
    meta: _91playback_id_93SJtCUEnHbKMeta || {},
    component: () => import("/opt/render/project/src/apps/admin/client/pages/videos/[playback_id].vue")
  }
]